import { Box, Button, Grid, HStack, Table, TableContainer, Tbody, Td, Th, Thead, Tr, Link, Stack, Flex, Spacer, Input, FormControl, FormLabel, InputLeftElement, InputGroup, IconButton, useColorModeValue } from "@chakra-ui/react";
import SalesAgreementSkeleton from "../../components/SalesAgreement/SalesAgreementSkeleton";
import SalesAgreements from '../../components/SalesAgreement/SalesAgreements';
import { useQuery } from '@tanstack/react-query';
import { ISalesAgreementDetail, ISalesAgreementList } from "../../types";
import { getMe, getSalesAgreement, getSalesAgreements } from '../../api';
import { SearchIcon } from "@chakra-ui/icons";
import { useEffect, useState } from "react";

interface IUser {
    id: number;
    name: string;
    username: string;
    role: string;
    minimax_number: string;
}

export default function SalesAgreement(props: {
	variant?: string;
	background?: string;
	children?: JSX.Element;
	placeholder?: string;
	borderRadius?: string | number;
	[x: string]: any;
}) {

    const { variant, background, children, placeholder, borderRadius, ...rest } = props;
	// Chakra Color Mode
	const searchIconColor = useColorModeValue('gray.700', 'white');
	const inputBg = useColorModeValue('secondaryGray.300', 'navy.900');
	const inputText = useColorModeValue('gray.700', 'gray.100');
    const [page, setPage] = useState(1)

    const { isLoading, data, isPreviousData } = useQuery<ISalesAgreementList[]>(["salesagreements"],  getSalesAgreements);
    const { data: salesagreementDetailData } = useQuery<ISalesAgreementDetail>([`salesagreements`], getSalesAgreement);

    const { data: userData, isLoading: isUserLoading } = useQuery<IUser>([`users`], getMe);
    // const [isLoading, setIsLoading] = useState(true);
    // const [quotations, setQuotations] = useState<IQuotation[]>([]);
    // const fetchQuotations = async() => {
    //     const response = await fetch("http://12asdf7.0.0.1:8000/api/v1/quotations/");
    //     const json = await response.json();
    //     setQuotations(json);
    //     setIsLoading(false);
    // } 
    // useEffect(() => {
    //     fetchQuotations();
    // }, [])
    const minimax_number = userData?.minimax_number;
    const minimax_name = userData?.name;
    const [query, setQuery] = useState("")

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            let inputHandler = (e:any) => {
                var lowerCase = e.target.value.toLowerCase();
                setQuery(lowerCase)
            }
        },3000)
        return () => clearTimeout(delayDebounceFn)
    }, [])    
    
    let salesagreementList;
    function RoleCheck() {
        if(userData?.role == "Boss" || userData?.role == "Manager" || userData?.name == "Charlie Hong" || userData?.name == "Sean Hyun") {
            return <Tbody>
            {data?.filter((data) => {
                if(query === '') { 
                    return data;
                } else if
                (data.salesagreement_id.toLowerCase().includes(query) || data.user.name.toLowerCase().includes(query.toLowerCase()) || data.client.name.toLowerCase().includes(query.toLowerCase()) || data.client.clinic.toLowerCase().includes(query.toLowerCase())) {
                    return data;
                }  
            }).map((salesagreement) => (
            <SalesAgreements
            key={salesagreement.id}
            id={salesagreement.id}
            kind={salesagreement.kind}
            payment_method={salesagreement.payment_method}
            payment_term={salesagreement.payment_term}
            salesagreement_id={salesagreement.salesagreement_id}
            date={salesagreement.date}
            name={salesagreement.user.name}
            dentist={salesagreement.client.name}
            clinic={salesagreement.client.clinic}
            address={salesagreement.client.address}
            mobile={salesagreement.client.mobile}
            email={salesagreement.client.email}
            abn={salesagreement.client.abn}
            salesagreement_items={salesagreement.salesagreement_items}
            salesagreement_pays={salesagreement.salesagreement_pays}
            satncs={salesagreement.satncs}
            sa_total_price={salesagreement.sa_total_price}
            sa_gst_price={salesagreement.sa_gst_price}
            sa_final_price={salesagreement.sa_final_price}

            />
            ))}
            </Tbody>
        }
        else if(userData?.role == "Management Team" && (userData?.name == ("Eric Kim") || userData?.name == ("Seungyong Seo")) ) {
            return <Tbody>
             {data?.filter((data) => { 
                if(data.user.minimax_number===minimax_number) {
                    if(query === '') { 
                        return data;
                    } else if (data.salesagreement_id.toLowerCase().includes(query) || data.user.name.toLowerCase().includes(query.toLowerCase()) || data.client.name.toLowerCase().includes(query.toLowerCase()) || data.client.clinic.toLowerCase().includes(query.toLowerCase())) {
                        return data;
                    }
                }
            }).map((salesagreement) => (
            salesagreementList = <SalesAgreements
            key={salesagreement.id}
            id={salesagreement.id}
            kind={salesagreement.kind}
            payment_method={salesagreement.payment_method}
            payment_term={salesagreement.payment_term}

            salesagreement_id={salesagreement.salesagreement_id}
            date={salesagreement.date}
            name={salesagreement.user.name}
            dentist={salesagreement.client.name}
            clinic={salesagreement.client.clinic}
            address={salesagreement.client.address}
            mobile={salesagreement.client.mobile}
            email={salesagreement.client.email}
            abn={salesagreement.client.abn}
            salesagreement_items={salesagreement.salesagreement_items}
            salesagreement_pays={salesagreement.salesagreement_pays}
            satncs={salesagreement.satncs}
            sa_total_price={salesagreement.sa_total_price}
            sa_gst_price={salesagreement.sa_gst_price}
            sa_final_price={salesagreement.sa_final_price}
            /> 
            ))}
            </Tbody>
        }
        else if(userData?.role == "Sales Team") {
            return <Tbody>
                {data?.filter((data) => { 
                if(data.user.minimax_number===minimax_number) {
                    if(query === '') { 
                        return data;
                    } else if (data.salesagreement_id.toLowerCase().includes(query) || data.user.name.toLowerCase().includes(query.toLowerCase()) || data.client.name.toLowerCase().includes(query.toLowerCase()) || data.client.clinic.toLowerCase().includes(query.toLowerCase())) {
                        return data;
                    }
                }
            }).map((salesagreement) => (
            salesagreementList = <SalesAgreements
            key={salesagreement.id}
            id={salesagreement.id}
            kind={salesagreement.kind}
            payment_method={salesagreement.payment_method}
            payment_term={salesagreement.payment_term}

            salesagreement_id={salesagreement.salesagreement_id}
            date={salesagreement.date}
            name={salesagreement.user.name}
            dentist={salesagreement.client.name}
            clinic={salesagreement.client.clinic}
            address={salesagreement.client.address}
            mobile={salesagreement.client.mobile}
            email={salesagreement.client.email}
            abn={salesagreement.client.abn}
            salesagreement_items={salesagreement.salesagreement_items}
            salesagreement_pays={salesagreement.salesagreement_pays}
            satncs={salesagreement.satncs}
            sa_total_price={salesagreement.sa_total_price}
            sa_gst_price={salesagreement.sa_gst_price}
            sa_final_price={salesagreement.sa_final_price}
            /> 
            ))}
            </Tbody>
        }
        return salesagreementList = <Tbody><Tr><Td></Td></Tr></Tbody>
    }
    return (
        <Grid mt={10} columnGap={7} rowGap={5} templateColumns={{ base: "repeat(1fr)", lg: "repeat(1fr)"}} px={{base: 0, lg: 10}}>
            {isLoading ? (
            <>
                <SalesAgreementSkeleton />
                <SalesAgreementSkeleton />
                <SalesAgreementSkeleton />
                <SalesAgreementSkeleton />
                <SalesAgreementSkeleton />
                <SalesAgreementSkeleton />
                <SalesAgreementSkeleton />
                <SalesAgreementSkeleton />
                <SalesAgreementSkeleton />
                <SalesAgreementSkeleton />
                <SalesAgreementSkeleton />
                <SalesAgreementSkeleton />
                <SalesAgreementSkeleton />
            </>
            ) : null }
            
            <Flex>
            <FormControl>
            <InputGroup w={{ base: '100%', md: '50%' }} {...rest}>
                <InputLeftElement
                    children={
                        <IconButton
                            aria-label='search'
                            bg='none'
                            borderRadius='inherit'
                            _hover={{
                                bg: 'none',
                            }}
                            _active={{
                                bg: 'none',
                                transform: 'none',
                                borderRadius: 'inherit',
                                borderColor: 'transparent'
                            }}
                            _focus={{
                                boxShadow: 'none',
                                borderRadius:'inherit'
                            }}
                            icon={<SearchIcon color={searchIconColor} w='15px' h='15px' borderRadius='inherit'
                            />}
                        />
                    }
                />
                <Input
                    variant='search'
                    border="1px solid #f0f0f0"

                    fontSize='sm'
                    color={inputText}
                    fontWeight='500'
                    _placeholder={{ color: 'gray.400', fontSize: '14px' }}
                    borderRadius={borderRadius ? borderRadius : '30px'}
                    placeholder={placeholder ? placeholder : 'Search...'}
                    type="text"
                    onChange={(e) => setQuery(e.target.value)}
                    />
            </InputGroup>
            </FormControl>
            
            <Spacer />

            <Link href="/salesagreements/upload">
                <Button size="sm">+ 새 계약서 만들기</Button>
            </Link>
            </Flex>
            <Box>
                <HStack>
                    <TableContainer w="100%">
                        <Table size='sm'>
                            <Thead>
                                <Tr>
                                    <Th>Ref. No</Th>
                                    {userData?.role == "Boss" && (
                                    <Th>수정</Th>
                                    )}
                                    {userData?.name == "Charlie Hong" && (
                                    <Th>수정</Th>
                                    )}
                                    <Th>날짜</Th>
                                    <Th>세일즈</Th>
                                    <Th>치과의사</Th>
                                    <Th>클리닉</Th>
                                    <Th>Final Price</Th>
                                </Tr>
                            </Thead>
                            <RoleCheck />
                            
                        </Table>
                    </TableContainer>
                </HStack>
            </Box>
        </Grid>
    )
}  