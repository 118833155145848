import { Box, Button, Grid, HStack, Table, TableContainer, Tbody, Td, Th, Thead, Tr, Link, Stack, Flex, Spacer, Input, FormControl, FormLabel, InputLeftElement, InputGroup, IconButton, useColorModeValue } from "@chakra-ui/react";
import QuotationSkeleton from "../../components/Quotation/QuotationSkeleton";
import Quotations from '../../components/Quotation/Quotations';
import { useQuery } from '@tanstack/react-query';
import { IQuotationDetail, IQuotationList } from "../../types";
import { getMe, getQuotation, getQuotations } from '../../api';
import { SearchIcon } from "@chakra-ui/icons";
import { useEffect, useMemo, useState } from "react";
import axios from "axios";

interface IUser {
    id: number;
    name: string;
    username: string;
    role: string;
    minimax_number: string;
}

export default function Quotation(props: {
	variant?: string;
	background?: string;
	children?: JSX.Element;
	placeholder?: string;
	borderRadius?: string | number;
	[x: string]: any;
}) {

    const { variant, background, children, placeholder, borderRadius, ...rest } = props;
	// Chakra Color Mode
	const searchIconColor = useColorModeValue('gray.700', 'white');
	const inputBg = useColorModeValue('secondaryGray.300', 'navy.900');
	const inputText = useColorModeValue('gray.700', 'gray.100');
    const [page, setPage] = useState(1)

    const { isLoading, data, isPreviousData } = useQuery<IQuotationList[]>(["quotations"], getQuotations);
    const { data: quotationDetailData } = useQuery<IQuotationDetail>([`quotations`], getQuotation);

    const { data: userData, isLoading: isUserLoading } = useQuery<IUser>([`users`], getMe);
    // const [isLoading, setIsLoading] = useState(true);
    // const [quotations, setQuotations] = useState<IQuotation[]>([]);
    // const fetchQuotations = async() => {
    //     const response = await fetch("http://127.0.0.1:8000/api/v1/quotations/");
    //     const json = await response.json();
    //     setQuotations(json);
    //     setIsLoading(false);
    // } 
    // useEffect(() => {
    //     fetchQuotations();
    // }, [])
    const minimax_number = userData?.minimax_number;
    const [query, setQuery] = useState("")

    const nextPage = () => setPage(prev=> prev+1)
    const prevPage = () => setPage(prev=> prev-1)
    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            let inputHandler = (e:any) => {
                var lowerCase = e.target.value.toLowerCase();
                setQuery(lowerCase)
            }
        },3000)
        return () => clearTimeout(delayDebounceFn)
    }, [])    
    let quotationList;
    function RoleCheck() {
        
        if(userData?.role == "Boss" || userData?.role == "Manager" || userData?.name == "Charlie Hong"  ||  userData?.name ==="Sean Hyun" ) {
            return <Tbody>
            {data?.filter((data) => {
                if(query === '') { 
                    return data;
                } else if
                (data.quotation_id.toLowerCase().includes(query.toLowerCase()) || data.user.name.toLowerCase().includes(query.toLowerCase()) || data.client.name.toLowerCase().includes(query.toLowerCase()) || data.client.clinic.toLowerCase().includes(query.toLowerCase()) || data.title.toLowerCase().includes(query.toLowerCase()) || data.kind.toLowerCase().includes(query.toLowerCase())) {
                    return data;
                } 
            }).map((quotation) => (
            <Quotations
            key={quotation.id}
            id={quotation.id}
            quotation_id={quotation.quotation_id}
            date={quotation.date}
            expire_date={quotation.expire_date}
            name={quotation.user.name}
            dentist={quotation.client.name}
            clinic={quotation.client.clinic}
            address={quotation.client.address}
            mobile={quotation.client.mobile}
            email={quotation.client.email}
            abn={quotation.client.abn}
            kind={quotation.kind}
            title={quotation.title}
            quotation_items={quotation.quotation_items}
            quotetncs={quotation.quotetncs}
            quote_total_price={quotation.quote_total_price}
            quote_gst_price={quotation.quote_gst_price}
            quote_final_price={quotation.quote_final_price}
            />
            ))}
            </Tbody>
        }
        // if(userData?.role == "Boss") {
        //     return <Tbody>
        //     {data?.filter((data) => {
        //         if(query === '') { 
        //             return data;
        //         } else if
        //         (data.quotation_id.toLowerCase().includes(query) || data.user.name.toLowerCase().includes(query) || data.client.name.toLowerCase().includes(query) || data.client.clinic.toLowerCase().includes(query) || data.title.toLowerCase().includes(query) || data.kind.toLowerCase().includes(query)) {
        //             return data;
        //         } 
        //     }).map((quotation) => (
        //     <Quotations
        //     key={quotation.id}
        //     id={quotation.id}
        //     quotation_id={quotation.quotation_id}
        //     date={quotation.date}
        //     expire_date={quotation.expire_date}
        //     name={quotation.user.name}
        //     dentist={quotation.client.name}
        //     clinic={quotation.client.clinic}
        //     address={quotation.client.address}
        //     mobile={quotation.client.mobile}
        //     email={quotation.client.email}
        //     abn={quotation.client.abn}
        //     kind={quotation.kind}
        //     title={quotation.title}
        //     quotation_items={quotation.quotation_items}
        //     quotetncs={quotation.quotetncs}
        //     quote_total_price={quotation.quote_total_price}
        //     quote_gst_price={quotation.quote_gst_price}
        //     quote_final_price={quotation.quote_final_price}
        //     />
        //     ))}
        //     </Tbody>
        // }
        else if(userData?.role == "Management Team" && ( userData?.name == ("Eric Kim")) || userData?.name == ("Seungyong Seo")) {
            return <Tbody>
            {data?.filter((data) => { 
                if(data.user.minimax_number===minimax_number) {
                    if(query === '') { 
                        return data;
                    } else if (data.quotation_id.toLowerCase().includes(query) || data.user.name.toLowerCase().includes(query.toLowerCase()) || data.client.name.toLowerCase().includes(query.toLowerCase()) || data.client.clinic.toLowerCase().includes(query.toLowerCase())) {
                        return data;
                    }
                }
            }).map((quotation) => (
            quotationList = <Quotations
            key={quotation.id}
            id={quotation.id}
            quotation_id={quotation.quotation_id}
            date={quotation.date}
            expire_date={quotation.expire_date}
            name={quotation.user.name}
            dentist={quotation.client.name}
            clinic={quotation.client.clinic}
            address={quotation.client.address}
            mobile={quotation.client.mobile}
            email={quotation.client.email}
            abn={quotation.client.abn}
            kind={quotation.kind}
            title={quotation.title}
            quotation_items={quotation.quotation_items}
            quotetncs={quotation.quotetncs}
            quote_total_price={quotation.quote_total_price}
            quote_gst_price={quotation.quote_gst_price}
            quote_final_price={quotation.quote_final_price}

            /> 
            ))}
            </Tbody>
        }
        else if(userData?.role == "Sales Team") {
            return <Tbody>
            {data?.filter((data) => { 
                if(data.user.minimax_number===minimax_number) {
                    if(query === '') { 
                        return data;
                    } else if (data.quotation_id.toLowerCase().includes(query) || data.user.name.toLowerCase().includes(query.toLowerCase()) || data.client.name.toLowerCase().includes(query.toLowerCase()) || data.client.clinic.toLowerCase().includes(query.toLowerCase())) {
                        return data;
                    }
                }
            }).map((quotation) => (
            quotationList = <Quotations
            key={quotation.id}
            id={quotation.id}
            quotation_id={quotation.quotation_id}
            date={quotation.date}
            expire_date={quotation.expire_date}
            name={quotation.user.name}
            dentist={quotation.client.name}
            clinic={quotation.client.clinic}
            address={quotation.client.address}
            mobile={quotation.client.mobile}
            email={quotation.client.email}
            abn={quotation.client.abn}
            kind={quotation.kind}
            title={quotation.title}
            quotation_items={quotation.quotation_items}
            quotetncs={quotation.quotetncs}
            quote_total_price={quotation.quote_total_price}
            quote_gst_price={quotation.quote_gst_price}
            quote_final_price={quotation.quote_final_price}

            /> 
            ))}
            </Tbody>
        }
        return quotationList = <Tbody><Tr><Td></Td></Tr></Tbody>    
    }
    return (
        <Grid mt={10} columnGap={7} rowGap={5} templateColumns={{ base: "repeat(1fr)", lg: "repeat(1fr)"}} px={{base: 0, lg: 10}}>
            {isLoading ? (
            <>
                <QuotationSkeleton />
                <QuotationSkeleton />
                <QuotationSkeleton />
                <QuotationSkeleton />
                <QuotationSkeleton />
                <QuotationSkeleton />
                <QuotationSkeleton />
                <QuotationSkeleton />
                <QuotationSkeleton />
                <QuotationSkeleton />
                <QuotationSkeleton />
                <QuotationSkeleton />
                <QuotationSkeleton />
                <QuotationSkeleton />
                <QuotationSkeleton />
                <QuotationSkeleton />
                <QuotationSkeleton />
                <QuotationSkeleton />
            </>
            ) : null }
            
            <Flex>
            <FormControl>
            <InputGroup w={{ base: '100%', md: '50%' }} {...rest}>
                <InputLeftElement
                    children={
                        <IconButton
                            aria-label='search'
                            bg='none'
                            borderRadius='inherit'
                            _hover={{
                                bg: 'none',
                            }}
                            _active={{
                                bg: 'none',
                                transform: 'none',
                                borderRadius: 'inherit',
                                borderColor: 'transparent'
                            }}
                            _focus={{
                                boxShadow: 'none',
                                borderRadius:'inherit'
                            }}
                            icon={<SearchIcon color={searchIconColor} w='15px' h='15px' borderRadius='inherit'
                            />}
                        />
                    }
                />
                <Input
                    variant='search'
                    border="1px solid #f0f0f0"

                    fontSize='sm'
                    color={inputText}
                    fontWeight='500'
                    _placeholder={{ color: 'gray.400', fontSize: '14px' }}
                    borderRadius={borderRadius ? borderRadius : '30px'}
                    placeholder={placeholder ? placeholder : 'Search...'}
                    type="text"
                    onChange={(e) => setQuery(e.target.value)}
                    />

            </InputGroup>
            </FormControl>
            
            <Spacer />

            <Link href="/quotations/upload">
                <Button size="sm">+ 새 견적서 만들기</Button>
            </Link>
            </Flex>
            <Box>
                <HStack>
                    <TableContainer w="100%">
                        <Table size='sm'>
                            <Thead>
                                <Tr>
                                    <Th>Ref. No</Th>
                                    <Th>날짜</Th>
                                    <Th>세일즈</Th>
                                    <Th>치과의사</Th>
                                    <Th>클리닉</Th>
                                    <Th>타이틀</Th>
                                    <Th>Final Price</Th>
                                    <Th>재견적</Th>
                                    <Th>계약서</Th>
                                </Tr>
                            </Thead>
                            <RoleCheck />
                            {/* <Box>
                                <Button onClick={prevPage} disabled={isPreviousData || page === 1}>Prev</Button>
                                <Button onClick={nextPage} disabled={isPreviousData}>Next</Button>
                            </Box> */}
                        </Table>
                    </TableContainer>
                </HStack>
            </Box>
        </Grid>
    )
}  