import { useQuery } from "@tanstack/react-query";
import { Link, useNavigate, useParams } from "react-router-dom"
import { getMe, getQuotation } from "../../api";
import { IQuotationDetail, IUser } from "../../types";
import { Box, Button, Container, Flex, HStack } from "@chakra-ui/react";
import { Document, Page, Text, View, StyleSheet, Image, PDFViewer, PDFDownloadLink } from '@react-pdf/renderer';
import Footer from "../../components/PDF/Quotation/Footer";
import Header from "../../components/PDF/Quotation/Header";
import { VscFilePdf } from "react-icons/vsc";
import { useMemo } from "react";
import Middle1 from "../../components/PDF/Quotation/Middle1";
import QuotationDetailSkeleton from "../../components/Quotation/QuotationDetailSkeleton";

const styles = StyleSheet.create({
    body: {
    backgroundColor: '#ffffff',
    fontFamiy: 'Source Sans',
    fontSize: 10,
    lineHeight: 1.4,
    padding: 16,
    paddingHorizontal: 16,
    height: '100vh',
    display: 'flex',
    size: "A4",
    },
    top: {
        flex: 1.2,
    },
    middle1: {
        flex: 2,
    },
    middle2: {
        flex: 5.5,

    },
    bottom: {
        justifyContent: "flex-end",
        flex: 1.5,
        fontSize: 8,
    },
})

export default function QuotationDetail() {
    const { quotationPk } = useParams();
    const navigate = useNavigate();

    const { isLoading, data } = useQuery<IQuotationDetail>([`quotations`, quotationPk], getQuotation);
    const { data: userData } = useQuery<IUser>([`users`], getMe);
    const minimax_number = userData?.minimax_number;
    const qcdentist = data?.client.name!
    const qcclinic = data?.client.clinic!
    const qcaddress = data?.client.address!
    const qcmobile = data?.client.mobile!
    const qcemail = data?.client.email!
    const qcabn = data?.client.abn!
    const qid = data?.quotation_id!
    const quname = data?.user.name!
    const qdate = data?.date!
    const qexpireddate = data?.expire_date!

const MyPDF = () => (
    <Document>
        <Page style={styles.body} >
            <View style={styles.top}>
                <Header quotation_id={qid}>Quotation</Header>
            </View>
            <View style={styles.middle1}>
                <Middle1 client_name={qcdentist} client_clinic={qcclinic} client_address={qcaddress} client_mobile={qcmobile} client_email={qcemail} client_abn={qcabn} quotation_id={qid} user_name={quname} date={qdate} expire_date={qexpireddate}></Middle1>
            </View>
            

            <View style={styles.middle2}>
                <View style={{display: 'flex'}}>
                    <View style={{flexDirection: "row", textAlign: "center"}}>
                        <Text style={{flex: 1, backgroundColor: "#0765A3", color: "white", paddingTop: "4px", paddingBottom: "3px", fontSize: "9px"}}>ID</Text>
                        <Text style={{flex: 6, backgroundColor: "#0765A3", color: "white", paddingTop: "4px", paddingBottom: "3px", fontSize: "9px"}}>Description</Text>
                        <Text style={{flex: 1, backgroundColor: "#0765A3", color: "white", paddingTop: "4px", paddingBottom: "3px", fontSize: "9px"}}>Qty</Text>
                        <Text style={{flex: 2, backgroundColor: "#0765A3", color: "white", paddingTop: "4px", paddingBottom: "3px", fontSize: "9px"}}>Unit Price</Text>
                        <Text style={{flex: 2, backgroundColor: "#0765A3", color: "white", paddingTop: "4px", paddingBottom: "3px", fontSize: "9px"}}>Amount (inc. gst)</Text>
                    </View>
                </View>
                {data?.quotation_items.map((key, index) => {
                    return <View key={index} style={{display: 'flex'}}>
                        <View style={{flexDirection: "row", textAlign: "center"}}>
                            {(key.unit_price > 0) ? 
                            <>
                                <Text style={{flex: 1, paddingTop: "5px", paddingBottom: "3px", borderBottom: "1px solid #D1D3D4", borderRight: "1px solid #D1D3D4", fontSize: "8px"}} >{index+1}</Text>
                                <Text style={{flex: 6, paddingTop: "5px", paddingBottom: "3px", borderBottom: "1px solid #D1D3D4", borderRight: "1px solid #D1D3D4", fontSize: "8px"}}>{key.description}</Text>
                                <Text style={{flex: 1, paddingTop: "5px", paddingBottom: "3px", borderBottom: "1px solid #D1D3D4", borderRight: "1px solid #D1D3D4", fontSize: "8px"}}>{key.qty}</Text>
                                <Text style={{flex: 2, paddingTop: "5px", paddingBottom: "3px", borderBottom: "1px solid #D1D3D4", borderRight: "1px solid #D1D3D4", fontSize: "8px"}}>{(Math.round(key.unit_price*100 /100).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","))}</Text>
                                <Text style={{flex: 2, paddingTop: "5px", paddingBottom: "3px", borderBottom: "1px solid #D1D3D4", fontSize: "8px"}}>{(Math.round(key.amount*100 /100).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","))}</Text>
                            </>
                            : 
                            <>
                                <Text style={{flex: 1, paddingTop: "5px", color: "red", paddingBottom: "3px", borderBottom: "1px solid #D1D3D4", borderRight: "1px solid #D1D3D4", fontSize: "8px"}}>{index+1}</Text>
                                <Text style={{flex: 6, color: "red", paddingTop: "5px", paddingBottom: "3px", borderBottom: "1px solid #D1D3D4", borderRight: "1px solid #D1D3D4", fontSize: "8px"}}>{key.description}</Text>
                                <Text style={{flex: 1, color: "red", paddingTop: "5px", paddingBottom: "3px", borderBottom: "1px solid #D1D3D4", borderRight: "1px solid #D1D3D4", fontSize: "8px"}}>{key.qty}</Text>
                                <Text style={{flex: 2, color: "red", paddingTop: "5px", paddingBottom: "3px", borderBottom: "1px solid #D1D3D4", borderRight: "1px solid #D1D3D4", fontSize: "8px"}}>{(Math.round(key.unit_price*100 /100).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","))}</Text>
                                <Text style={{flex: 2, color: "red", paddingTop: "5px", paddingBottom: "3px", borderBottom: "1px solid #D1D3D4", fontSize: "8px"}}>{(Math.round(key.amount*100 /100).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","))}</Text>
                            </>
                            }
                        </View>
                    </View>
                    ;
                })}
                <View style={{display: "flex"}}>
                    <View style={{flexDirection: 'row'}}>
                        <View style={{flex: 27, padding: "20px", marginTop: "10px"}}>
                            <Text style={{color: "red", fontSize: "8px"}}>{data?.special_condition}</Text>
                        </View>
                        <View style={{flex: 13, marginTop: "10px", padding: "20px",}}>
                            <View style={{display: "flex"}}>
                                <View style={{flexDirection: "row", justifyContent: "flex-end"}}>
                                    <View style={{}}>
                                        <Text style={{backgroundColor: "#0765A3", color: "#fff", fontSize: "11px", marginBottom: "3px", marginRight: "2px", paddingTop: "6px", paddingBottom: "3px", paddingRight: "7px", paddingLeft: "10px"}}>Total Price</Text>
                                        <Text style={{backgroundColor: "#0765A3", color: "#fff", fontSize: "11px", marginBottom: "3px", marginRight: "2px", paddingTop: "6px", paddingBottom: "3px", paddingRight: "7px", paddingLeft: "10px"}}>GST Total</Text>
                                        <Text style={{backgroundColor: "#0765A3", color: "#fff", fontSize: "11px", marginRight: "2px", paddingTop: "6px", paddingBottom: "3px", paddingRight: "7px", paddingLeft: "10px"}}>Final Price</Text>
                                    </View>
                                    <View style={{textAlign: "center", alignItems: "flex-end"}}>
                                        <Text style={{fontSize: "11px", marginLeft: "10px", marginBottom: "3px", textAlign: "center", paddingTop: "6px", paddingBottom: "3px", paddingRight: "7px", paddingLeft: "10px"}}>$ {Math.round(data?.quote_total_price! *100/100).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</Text>
                                        <Text style={{fontSize: "11px", marginLeft: "10px", marginBottom: "3px", textAlign: "center", paddingTop: "6px", paddingBottom: "3px", paddingRight: "7px", paddingLeft: "10px"}}>$ {Math.round(data?.quote_gst_price! *100/100).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</Text>
                                        <Text style={{fontSize: "11px", marginLeft: "10px", marginBottom: "3px", textAlign: "center", paddingTop: "6px", paddingBottom: "3px", paddingRight: "7px", paddingLeft: "10px"}}>$ {Math.round(data?.quote_final_price! *100/100).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</Text>
                                    </View>
                                </View>
                            </View>
                        </View>
                    </View>
                </View>
            </View>
                
            <View style={styles.bottom}>
                <View style={{paddingLeft: "30px", paddingRight: "30px"}}>
                    <Text style={{fontSize: "11px", marginBottom: "5px"}}>Terms & Conditions</Text>
                    {data?.quotetncs.map((key, index) => {
                    return <Text key={index}>
                        <Text style={{}}>{key.description}</Text>
                    </Text>
                    ;
                } )}
                </View>
                {/* <ProposalSignature/> */}
                <Footer/>
            </View>
            {/* <Text render={({pageNumber, totalPages}) => `${pageNumber} / ${totalPages}`}></Text> */}
        </Page>
    </Document>
    )
    const PDFMemo = useMemo(
        () => (
            <PDFDownloadLink document={<MyPDF />} fileName={`${data?.user.name}_${data?.quotation_id}`}>
            {({loading}) => 
                loading? (
                <>Loading Document...</>
                ) : (
                    <VscFilePdf />
                )
            }
            </PDFDownloadLink>
        ), [])

    function PDF() {
        return PDFMemo;
    }
    function CheckUser() {
        if(userData?.role === "Boss" && data?.quotation_id || userData?.name ==="Charlie Hong"  && data?.quotation_id ||  userData?.name ==="Sean Hyun" && data?.quotation_id  || userData?.role ==="Manager" && data?.quotation_id  || userData?.name ==="Seungyong Seo" && data?.quotation_id) {
            return <>
            <Box 
                pb={20}
                pt={20}
                px={{  
                    base: 10,
                    md: 20,
                    lg: "80px",
                }}
                backgroundColor="#f9f9f9"
                borderRadius="20px"
                >
                <Box display="flex" justifyContent="space-between" mb="20">
                    <HStack><Box><Link to={`/quotations/`}><Button colorScheme='facebook'>&#x2190; 견적서 리스트</Button></Link></Box><Box><Link to={`/quotations/${quotationPk}/upload`}><Button background='#292929' color="#fff" colorScheme='blue'>견적서 수정해서 다시 만들기</Button></Link></Box><Box><Link to={`/salesagreements/${quotationPk}/upload`}><Button background='#292929' color="#fff" colorScheme='blue'>계약서 만들기</Button></Link></Box></HStack>
                    <Box><PDFDownloadLink document={<MyPDF />} fileName={`${data?.user.name}_${data?.quotation_id}`}>
                    {({loading}) => 
                        loading? (
                        <>Loading Document...</>
                        ) : (
                            <Button colorScheme='facebook'><VscFilePdf /></Button>
                        )
                    }
                    </PDFDownloadLink>
                    </Box>
                </Box>
                {/* Quotation Information */}
                
                {/* <Container>
                <HStack ><Box>TITLE: </Box><Box>{data?.title}</Box></HStack>
                </Container> */}
                <Box paddingRight="10" paddingLeft="10">
                <HStack mb="7"><Box fontSize="20" fontWeight="bold">{data?.client.name}</Box><Box>&</Box><Box fontSize="20" fontWeight="bold">{data?.client.clinic}</Box></HStack>

                <Box display="flex" justifyContent="space-between" mt={2} fontSize="15" >
                    <Flex>
                        <Box>
                            <Box display="flex" justifyContent="space-between">
                                <Flex mr={5}>
                                    <Box>
                                        <Box>Phone:</Box>
                                        <Box>Email:</Box>
                                        <Box>Address:</Box>
                                        <Box>ABN:</Box>
                                    </Box>
                                </Flex>
                                <Flex>
                                    <Box>
                                        <Box>{data?.client.mobile}</Box>
                                        <Box>{data?.client.email}</Box>
                                        <Box>{data?.client.address}</Box>
                                        <Box>{data?.client.abn}</Box>
                                    </Box>
                                </Flex>
                            </Box>
                        </Box>
                    </Flex>
                    <Flex>
                        <Box>
                            <Box display="flex" justifyContent="space-between">
                                <Flex mr={5}>
                                    <Box>
                                        <Box>Quotation ID:</Box>
                                        <Box>Sales Rep:</Box>
                                        <Box>Date:</Box>
                                        <Box>Expire Date:</Box>
                                    </Box>
                                </Flex>
                                <Flex>
                                    <Box>
                                        <Box>{data?.quotation_id}</Box>
                                        <Box>{data?.user.name}</Box>
                                        <Box>{data?.date}</Box>
                                        <Box>{data?.expire_date}</Box>
                                    </Box>
                                </Flex>
                            </Box>
                        </Box>
                    </Flex>
                </Box>
                </Box>
                
                
                {/* Quotation Itmes */}
                <Box display="flex" flexDirection="column" textAlign="center" fontSize="15" mt={20}>
                <Flex mt={5} bg="#385898" color="white" pt="7px" pb="7px">
                    <Box flex="1">ID</Box>
                    <Box flex="6">Description</Box>
                    <Box flex="1">QTY</Box>
                    <Box flex="2">UNIT PRICE</Box>
                    <Box flex="2">AMOUNT</Box>
                </Flex>
                
                {data?.quotation_items.map((key, index) => <Flex key={index}>
                    
                {(key.unit_price > 0) ?  
                <>
                    <Box flex="1" pt="7px" pb="10px" borderBottom="1px solid #D1D3D4" borderRight="1px solid #D1D3D4">{index+1}</Box>
                    <Box flex="6" pt="7px" pb="10px" borderBottom="1px solid #D1D3D4" borderRight="1px solid #D1D3D4">{key.description}</Box>
                    <Box flex="1" pt="7px" pb="10px" borderBottom="1px solid #D1D3D4" borderRight="1px solid #D1D3D4">{key.qty}</Box>
                    <Box flex="2" pt="7px" pb="10px" borderBottom="1px solid #D1D3D4" borderRight="1px solid #D1D3D4">{(Math.round(key.unit_price*100 /100).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","))}</Box>
                    <Box flex="2" pt="7px" pb="10px" borderBottom="1px solid #D1D3D4">{(Math.round(key.amount*100 /100).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","))}</Box>
                </> 
                : 
                <>
                    <Box flex="1" color="red" pt="7px" pb="10px" borderBottom="1px solid #D1D3D4" borderRight="1px solid #D1D3D4">{index+1}</Box>
                    <Box flex="6" color="red" pt="7px" pb="10px" borderBottom="1px solid #D1D3D4" borderRight="1px solid #D1D3D4">{key.description}</Box>
                    <Box flex="1" color="red" pt="7px" pb="10px" borderBottom="1px solid #D1D3D4" borderRight="1px solid #D1D3D4">{key.qty}</Box>
                    <Box flex="2" color="red" pt="7px" pb="10px" borderBottom="1px solid #D1D3D4" borderRight="1px solid #D1D3D4">{(Math.round(key.unit_price*100 /100).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","))}</Box>
                    <Box flex="2" color="red" pt="7px" pb="10px" borderBottom="1px solid #D1D3D4">{(Math.round(key.amount*100 /100).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","))}</Box>
                </> 
                }
                    
                </Flex> 
                )}
                </Box>

                <Box display="flex" justifyContent="space-between" mt={10} fontSize="15" paddingRight="10" paddingLeft="10">
                    <Flex flex="7">
                        <Box>
                            <Box display="flex">
                                <Flex mr={5}>
                                    <Box>
                                        <Box color="red" fontSize="10px"><pre><Box>{data?.special_condition}</Box></pre></Box>
                                    </Box>
                                </Flex>
                            </Box>
                        </Box>
                    </Flex>
                    <Flex flex={3}></Flex>
                    <Flex flex="0 0 250px" justifyContent="flex-end">
                        <Box fontSize="18px" background="#385898" color="#fff" padding="12px 0 6px 15px" borderRadius="20px">
                            <Box display="flex">
                                <Flex mr={5}>
                                    <Box>
                                        <HStack mb="10px"><Box fontWeight="700">Total Price: </Box></HStack>
                                        <HStack mb="10px"><Box fontWeight="700">GST: </Box></HStack>
                                        <HStack mb="10px"><Box fontWeight="700">Final Price: </Box></HStack>
                                    </Box>
                                </Flex>
                                <Flex mr={5}>
                                    <Box>
                                        <HStack mb="10px"><Box>{Math.round(data?.quote_total_price *100/100).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</Box></HStack>
                                        <HStack mb="10px"><Box>{Math.round(data?.quote_gst_price *100/100).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</Box></HStack>
                                        <HStack mb="10px"><Box>{Math.round(data?.quote_final_price *100/100).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</Box></HStack>
                                    </Box>
                                </Flex>
                            </Box>
                        </Box>
                    </Flex>
                </Box>
                {/* Special Condition */}

                {/* Terms & Condition */}
                {/* 
                {data?.quotetncs.map((key, index) => <Box key={index}>
                    <pre><Box mt={20}>{ key.description }</Box></pre>
                </Box> )}
                */}
                </Box>
            </>

        } else if (userData?.role === "Sales Team" && data?.quotation_id) {
            if(minimax_number === data?.user.minimax_number) {
                return <>
            <Box 
                pb={20}
                pt={20}
                px={{  
                    base: 10,
                    md: 20,
                    lg: "80px",
                }}
                backgroundColor="#f9f9f9"
                borderRadius="20px"
                >
                <Box display="flex" justifyContent="space-between" mb="20">
                    <HStack><Box><Link to={`/quotations/`}><Button colorScheme='facebook'>&#x2190; 견적서 리스트</Button></Link></Box><Box><Link to={`/salesagreements/${quotationPk}/upload`}><Button background='#292929' color="#fff" colorScheme='blue'>계약서 만들기</Button></Link></Box></HStack>
                    <Box><PDFDownloadLink document={<MyPDF />} fileName={`${data?.user.name}_${data?.quotation_id}`}>
                    {({loading}) => 
                        loading? (
                        <>Loading Document...</>
                        ) : (
                            <Button colorScheme='facebook'><VscFilePdf /></Button>
                        )
                    }
                    </PDFDownloadLink>
                    </Box>
                </Box>
                {/* Quotation Information */}
                
                {/* <Container>
                <HStack ><Box>TITLE: </Box><Box>{data?.title}</Box></HStack>
                </Container> */}
                <Box paddingRight="10" paddingLeft="10">
                <HStack mb="7"><Box fontSize="20" fontWeight="bold">{data?.client.name}</Box><Box>&</Box><Box fontSize="20" fontWeight="bold">{data?.client.clinic}</Box></HStack>

                <Box display="flex" justifyContent="space-between" mt={2} fontSize="15" >
                    <Flex>
                        <Box>
                            <Box display="flex" justifyContent="space-between">
                                <Flex mr={5}>
                                    <Box>
                                        <Box>Phone:</Box>
                                        <Box>Email:</Box>
                                        <Box>Address:</Box>
                                        <Box>ABN:</Box>
                                    </Box>
                                </Flex>
                                <Flex>
                                    <Box>
                                        <Box>{data?.client.mobile}</Box>
                                        <Box>{data?.client.email}</Box>
                                        <Box>{data?.client.address}</Box>
                                        <Box>{data?.client.abn}</Box>
                                    </Box>
                                </Flex>
                            </Box>
                        </Box>
                    </Flex>
                    <Flex>
                        <Box>
                            <Box display="flex" justifyContent="space-between">
                                <Flex mr={5}>
                                    <Box>
                                        <Box>Quotation ID:</Box>
                                        <Box>Sales Rep:</Box>
                                        <Box>Date:</Box>
                                        <Box>Expire Date:</Box>
                                    </Box>
                                </Flex>
                                <Flex>
                                    <Box>
                                        <Box>{data?.quotation_id}</Box>
                                        <Box>{data?.user.name}</Box>
                                        <Box>{data?.date}</Box>
                                        <Box>{data?.expire_date}</Box>
                                    </Box>
                                </Flex>
                            </Box>
                        </Box>
                    </Flex>
                </Box>
                </Box>
                
                
                {/* Quotation Itmes */}
                <Box display="flex" flexDirection="column" textAlign="center" fontSize="15" mt={20}>
                <Flex mt={5} bg="#385898" color="white" pt="7px" pb="7px">
                    <Box flex="1">ID</Box>
                    <Box flex="6">Description</Box>
                    <Box flex="1">QTY</Box>
                    <Box flex="2">UNIT PRICE</Box>
                    <Box flex="2">AMOUNT</Box>
                </Flex>
                
                {data?.quotation_items.map((key, index) => <Flex key={index}>
                    
                {(key.unit_price > 0) ?  
                <>
                    <Box flex="1" pt="7px" pb="10px" borderBottom="1px solid #D1D3D4" borderRight="1px solid #D1D3D4">{index+1}</Box>
                    <Box flex="6" pt="7px" pb="10px" borderBottom="1px solid #D1D3D4" borderRight="1px solid #D1D3D4">{key.description}</Box>
                    <Box flex="1" pt="7px" pb="10px" borderBottom="1px solid #D1D3D4" borderRight="1px solid #D1D3D4">{key.qty}</Box>
                    <Box flex="2" pt="7px" pb="10px" borderBottom="1px solid #D1D3D4" borderRight="1px solid #D1D3D4">{(Math.round(key.unit_price*100 /100).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","))}</Box>
                    <Box flex="2" pt="7px" pb="10px" borderBottom="1px solid #D1D3D4">{(Math.round(key.amount*100 /100).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","))}</Box>
                </> 
                : 
                <>
                    <Box flex="1" color="red" pt="7px" pb="10px" borderBottom="1px solid #D1D3D4" borderRight="1px solid #D1D3D4">{index+1}</Box>
                    <Box flex="6" color="red" pt="7px" pb="10px" borderBottom="1px solid #D1D3D4" borderRight="1px solid #D1D3D4">{key.description}</Box>
                    <Box flex="1" color="red" pt="7px" pb="10px" borderBottom="1px solid #D1D3D4" borderRight="1px solid #D1D3D4">{key.qty}</Box>
                    <Box flex="2" color="red" pt="7px" pb="10px" borderBottom="1px solid #D1D3D4" borderRight="1px solid #D1D3D4">{(Math.round(key.unit_price*100 /100).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","))}</Box>
                    <Box flex="2" color="red" pt="7px" pb="10px" borderBottom="1px solid #D1D3D4">{(Math.round(key.amount*100 /100).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","))}</Box>
                </> 
                }
                    
                </Flex> 
                )}
                </Box>

                <Box display="flex" justifyContent="space-between" mt={10} fontSize="15" paddingRight="10" paddingLeft="10">
                    <Flex flex="7">
                        <Box>
                            <Box display="flex">
                                <Flex mr={5}>
                                    <Box>
                                        <Box color="red" fontSize="10px"><pre><Box>{data?.special_condition}</Box></pre></Box>
                                    </Box>
                                </Flex>
                            </Box>
                        </Box>
                    </Flex>
                    <Flex flex={3}></Flex>
                    <Flex flex="0 0 250px" justifyContent="flex-end">
                        <Box fontSize="18px" background="#385898" color="#fff" padding="12px 0 6px 15px" borderRadius="20px">
                            <Box display="flex">
                                <Flex mr={5}>
                                    <Box>
                                        <HStack mb="10px"><Box fontWeight="700">Total Price: </Box></HStack>
                                        <HStack mb="10px"><Box fontWeight="700">GST: </Box></HStack>
                                        <HStack mb="10px"><Box fontWeight="700">Final Price: </Box></HStack>
                                    </Box>
                                </Flex>
                                <Flex mr={5}>
                                    <Box>
                                        <HStack mb="10px"><Box>{Math.round(data?.quote_total_price *100/100).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</Box></HStack>
                                        <HStack mb="10px"><Box>{Math.round(data?.quote_gst_price *100/100).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</Box></HStack>
                                        <HStack mb="10px"><Box>{Math.round(data?.quote_final_price *100/100).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</Box></HStack>
                                    </Box>
                                </Flex>
                            </Box>
                        </Box>
                    </Flex>
                </Box>
                {/* Special Condition */}

                {/* Terms & Condition */}
                {/* 
                {data?.quotetncs.map((key, index) => <Box key={index}>
                    <pre><Box mt={20}>{ key.description }</Box></pre>
                </Box> )}
                */}
                </Box>
            </>
            } else {
                navigate("/")
            }
        } else {
            navigate("/")
        }
        return <Box>{data?.quotation_id}</Box>
    }
    return (
        <Box>
        {isLoading ? (
            <>
            <QuotationDetailSkeleton />
            </>
        ) : null }
            <Box>
                <CheckUser />
            </Box>
        </Box>
    )
}