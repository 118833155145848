import React, { useEffect, useState } from 'react';
import { Box, Button, Grid, HStack, Skeleton, Table, TableContainer, Tbody, Td, Text, Tfoot, Th, Thead, Tr, Link, Stack, Flex, FormControl, InputGroup, InputLeftElement, IconButton, Input, Spacer, useColorModeValue } from "@chakra-ui/react";
import { VscFilePdf } from 'react-icons/vsc';
import LeaveformSkeleton from "../../components/Leaveform/LeaveformSkeleton";
import Leaveforms from '../../components/Leaveform/Leaveforms';
import { useQuery } from '@tanstack/react-query';
import { ILeaveformDetail, ILeaveformList } from "../../types";
import { getMe, getLeaveforms, getLeaveform } from '../../api';
import useUser from '../../lib/useUser';
import { SearchIcon } from '@chakra-ui/icons';
import * as XLSX from "xlsx";


interface IUser {
    id: number;
    name: string;
    username: string;
    role: string;
    minimax_number: string;
}
export default function Leaveform(props: {
	variant?: string;
	background?: string;
	children?: JSX.Element;
	placeholder?: string;
	borderRadius?: string | number;
	[x: string]: any;
}) {

    const { variant, background, children, placeholder, borderRadius, ...rest } = props;
	// Chakra Color Mode
	const searchIconColor = useColorModeValue('gray.700', 'white');
	const inputBg = useColorModeValue('secondaryGray.300', 'navy.900');
	const inputText = useColorModeValue('gray.700', 'gray.100');

    const { isLoading, data } = useQuery<ILeaveformList[]>(["leaveforms"], getLeaveforms);
    const { data: quotationDetailData } = useQuery<ILeaveformDetail>([`leaveforms`], getLeaveform);

    const { data: userData, isLoading: isUserLoading } = useQuery<IUser>([`users`], getMe);
    const minimax_number = userData?.minimax_number;
    const [query, setQuery] = useState("");
    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            let inputHandler = (e:any) => {
                var lowerCase = e.target.value.toLowerCase();
                setQuery(lowerCase)
            }
        },3000)
        return () => clearTimeout(delayDebounceFn)
    }, [])   
    
    
    const exportToExcel = () => {
        if (!data || data.length === 0) {
          console.error("No data available to export");
          return;
        }
        if(userData?.role === "Boss" || userData?.role === "Manager" || userData?.name === "Soorim Song" ) {
            const transformedData = data.map((item) => ({
                ...item,
                submitterName: item.submitter.name, // submitter의 name 필드를 추가
                leavestartday: item.leaveform_dates.map(leaveform_date => leaveform_date.leaveStartDay).join(", "), 
                leavestartmonth: item.leaveform_dates.map(leaveform_date => leaveform_date.leaveStartMonth).join(", "), 
                leavestartyear: item.leaveform_dates.map(leaveform_date => leaveform_date.leaveStartYear).join(", "), 
                leaveendday: item.leaveform_dates.map(leaveform_date => leaveform_date.leaveEndDay).join(", "), 
                leaveendmonth: item.leaveform_dates.map(leaveform_date => leaveform_date.leaveEndMonth).join(", "), 
                leaveendyear: item.leaveform_dates.map(leaveform_date => leaveform_date.leaveEndYear).join(", "), 

            }));
             // WorkSheet 생성
            const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(transformedData);
            // WorkBook 생성
            const wb: XLSX.WorkBook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
            // 엑셀 파일 다운로드
            XLSX.writeFile(wb, "data.xlsx");
        } else if (userData?.name === "Steven Yoo" || userData?.name === "Min Kim" || userData?.name === "Charlie Hong" || userData?.name === "Sean Hyun") {
            const filteredData = data.filter(item => item.submitter.role === userData?.role);

            // 데이터 변환
            const transformedData = filteredData.map((item) => ({
            ...item,
            submitterName: item.submitter.name, // submitter의 name 필드를 추가
            leavestartday: item.leaveform_dates.map(leaveform_date => leaveform_date.leaveStartDay).join(", "), 
            leavestartmonth: item.leaveform_dates.map(leaveform_date => leaveform_date.leaveStartMonth).join(", "), 
            leavestartyear: item.leaveform_dates.map(leaveform_date => leaveform_date.leaveStartYear).join(", "), 
            leaveendday: item.leaveform_dates.map(leaveform_date => leaveform_date.leaveEndDay).join(", "), 
            leaveendmonth: item.leaveform_dates.map(leaveform_date => leaveform_date.leaveEndMonth).join(", "), 
            leaveendyear: item.leaveform_dates.map(leaveform_date => leaveform_date.leaveEndYear).join(", "), 
            }));
             // WorkSheet 생성
            const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(transformedData);
            // WorkBook 생성
            const wb: XLSX.WorkBook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
            // 엑셀 파일 다운로드
            XLSX.writeFile(wb, "data.xlsx");
        } else {
            const filteredData = data.filter(item => item.submitter.name === userData?.name);

            // 데이터 변환
            const transformedData = filteredData.map((item) => ({
            ...item,
            submitterName: item.submitter.name, // submitter의 name 필드를 추가
            }));
             // WorkSheet 생성
            const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(transformedData);
            // WorkBook 생성
            const wb: XLSX.WorkBook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
            // 엑셀 파일 다운로드
            XLSX.writeFile(wb, "data.xlsx");
        }
    };
    
    
    let leaveformList;
    function RoleCheck() {
        if(userData?.role === "Boss" || userData?.role === "Manager" || userData?.name === "Soorim Song" || userData?.name === "Sean Hyun" || userData?.name === "Charlie Hong") {
            return <Tbody>
            {data?.filter((data) => { 
                if(query === '') { 
                    return data;
                } else if (data.submitter.name.toLowerCase().includes(query)) {
                    return data;
                }
                }).map((leaveform) => (
            leaveformList = <Leaveforms
            key={leaveform.id}
            id={leaveform.id}
            leaveform_id={leaveform.leaveform_id}
            submission_date={leaveform.submission_date}
            reasonForRequest={leaveform.reasonForRequest}
            remark={leaveform.remark}
            submitter={leaveform.submitter.name}
            kind={leaveform.kind}
            leaveform_dates={leaveform.leaveform_dates}
            leaveTotalDays={leaveform.leaveTotalDays}
            /> 
            ))}
            </Tbody>
        }   
        
        else if(userData?.role === "TS Team") {
            if(userData?.name === "Steven Yoo") {
                return <Tbody>
                    {data?.filter((data) => { 
                    if(data.submitter.role==="TS Team") {
                        if(query === '') { 
                            return data;
                        } else if (data.submitter.name.toLowerCase().includes(query) || data.kind.toLowerCase().includes(query)) {
                            return data;
                        }
                    }
                    }).map((leaveform) => (
                        leaveformList = <Leaveforms
                        key={leaveform.id}
                        id={leaveform.id}
                        leaveform_id={leaveform.leaveform_id}
                        submission_date={leaveform.submission_date}
                        reasonForRequest={leaveform.reasonForRequest}
                        remark={leaveform.remark}
                        submitter={leaveform.submitter.name}
                        kind={leaveform.kind}
                        leaveform_dates={leaveform.leaveform_dates}
                        leaveTotalDays={leaveform.leaveTotalDays}
    
                
                /> 
                ))}
                </Tbody>
            } else {
                return <Tbody>
                    {data?.filter((data) => { 
                    if(data.submitter.minimax_number===minimax_number) {
                        if(query === '') { 
                            return data;
                        } else if (data.submitter.name.toLowerCase().includes(query) || data.kind.toLowerCase().includes(query)) {
                            return data;
                        }
                    }
                    }).map((leaveform) => (
                        leaveformList = <Leaveforms
                        key={leaveform.id}
                        id={leaveform.id}
                        leaveform_id={leaveform.leaveform_id}
                        submission_date={leaveform.submission_date}
                        reasonForRequest={leaveform.reasonForRequest}
                        remark={leaveform.remark}
                        submitter={leaveform.submitter.name}
                        kind={leaveform.kind}
                        leaveform_dates={leaveform.leaveform_dates}
                        leaveTotalDays={leaveform.leaveTotalDays}
    
                
                /> 
                ))}
                </Tbody>
            }
            } 
        
        else if(userData?.role === "Sales Team") {
            if(userData?.name === "Charlie Hong") {
                return <Tbody>
                    {data?.filter((data) => { 
                    if(data.submitter.role==="Sales Team") {
                        if(query === '') { 
                            return data;
                        } else if (data.submitter.name.toLowerCase().includes(query) || data.kind.toLowerCase().includes(query)) {
                            return data;
                        }
                    }
                    }).map((leaveform) => (
                        leaveformList = <Leaveforms
                        key={leaveform.id}
                        id={leaveform.id}
                        leaveform_id={leaveform.leaveform_id}
                        submission_date={leaveform.submission_date}
                        reasonForRequest={leaveform.reasonForRequest}
                        remark={leaveform.remark}
                        submitter={leaveform.submitter.name}
                        kind={leaveform.kind}
                        leaveform_dates={leaveform.leaveform_dates}
                        leaveTotalDays={leaveform.leaveTotalDays}
    
                
                /> 
                ))}
                </Tbody>
            } else {
                return <Tbody>
                    {data?.filter((data) => { 
                    if(data.submitter.minimax_number===minimax_number) {
                        if(query === '') { 
                            return data;
                        } else if (data.submitter.name.toLowerCase().includes(query) || data.kind.toLowerCase().includes(query)) {
                            return data;
                        }
                    }
                    }).map((leaveform) => (
                        leaveformList = <Leaveforms
                        key={leaveform.id}
                        id={leaveform.id}
                        leaveform_id={leaveform.leaveform_id}
                        submission_date={leaveform.submission_date}
                        reasonForRequest={leaveform.reasonForRequest}
                        remark={leaveform.remark}
                        submitter={leaveform.submitter.name}
                        kind={leaveform.kind}
                        leaveform_dates={leaveform.leaveform_dates}
                        leaveTotalDays={leaveform.leaveTotalDays}
    
                
                /> 
                ))}
                </Tbody>
            }
            } 
        
        else if(userData?.role === "Management Team") {
        if(userData?.name === "Sini Park") {
            return <Tbody>
                {data?.filter((data) => { 
                if(data.submitter.role==="Management Team") {
                    if(query === '') { 
                        return data;
                    } else if (data.submitter.name.toLowerCase().includes(query) || data.kind.toLowerCase().includes(query)) {
                        return data;
                    }
                }
                }).map((leaveform) => (
                    leaveformList = <Leaveforms
                    key={leaveform.id}
                    id={leaveform.id}
                    leaveform_id={leaveform.leaveform_id}
                    submission_date={leaveform.submission_date}
                    reasonForRequest={leaveform.reasonForRequest}
                    remark={leaveform.remark}
                    submitter={leaveform.submitter.name}
                    kind={leaveform.kind}
                    leaveform_dates={leaveform.leaveform_dates}
                    leaveTotalDays={leaveform.leaveTotalDays}

            
            /> 
            ))}
            </Tbody>
        } else {
            return <Tbody>
                {data?.filter((data) => { 
                if(data.submitter.minimax_number===minimax_number) {
                    if(query === '') { 
                        return data;
                    } else if (data.submitter.name.toLowerCase().includes(query) || data.kind.toLowerCase().includes(query)) {
                        return data;
                    }
                }
                }).map((leaveform) => (
                    leaveformList = <Leaveforms
                    key={leaveform.id}
                    id={leaveform.id}
                    leaveform_id={leaveform.leaveform_id}
                    submission_date={leaveform.submission_date}
                    reasonForRequest={leaveform.reasonForRequest}
                    remark={leaveform.remark}
                    submitter={leaveform.submitter.name}
                    kind={leaveform.kind}
                    leaveform_dates={leaveform.leaveform_dates}
                    leaveTotalDays={leaveform.leaveTotalDays}

            
            /> 
            ))}
            </Tbody>
        }
        }
        else if(userData?.role === "Marketing Team") {
            if(userData?.name === "Daniel Song") {
                return <Tbody>
                    {data?.filter((data) => { 
                    if(data.submitter.role==="Marketing Team") {
                        if(query === '') { 
                            return data;
                        } else if (data.submitter.name.toLowerCase().includes(query) || data.kind.toLowerCase().includes(query)) {
                            return data;
                        }
                    }
                    }).map((leaveform) => (
                        leaveformList = <Leaveforms
                        key={leaveform.id}
                        id={leaveform.id}
                        leaveform_id={leaveform.leaveform_id}
                        submission_date={leaveform.submission_date}
                        reasonForRequest={leaveform.reasonForRequest}
                        remark={leaveform.remark}
                        submitter={leaveform.submitter.name}
                        kind={leaveform.kind}
                        leaveform_dates={leaveform.leaveform_dates}
                        leaveTotalDays={leaveform.leaveTotalDays}
    
                
                /> 
                ))}
                </Tbody>
            } else {
                return <Tbody>
                    {data?.filter((data) => { 
                    if(data.submitter.minimax_number===minimax_number) {
                        if(query === '') { 
                            return data;
                        } else if (data.submitter.name.toLowerCase().includes(query) || data.kind.toLowerCase().includes(query)) {
                            return data;
                        }
                    }
                    }).map((leaveform) => (
                        leaveformList = <Leaveforms
                        key={leaveform.id}
                        id={leaveform.id}
                        leaveform_id={leaveform.leaveform_id}
                        submission_date={leaveform.submission_date}
                        reasonForRequest={leaveform.reasonForRequest}
                        remark={leaveform.remark}
                        submitter={leaveform.submitter.name}
                        kind={leaveform.kind}
                        leaveform_dates={leaveform.leaveform_dates}
                        leaveTotalDays={leaveform.leaveTotalDays}
    
                
                /> 
                ))}
                </Tbody>
            }
            }
            else if(userData?.role === "Digital Team") {
                if(userData?.name === "Chole Chea") {
                    return <Tbody>
                        {data?.filter((data) => { 
                        if(data.submitter.role==="Digital Team") {
                            if(query === '') { 
                                return data;
                            } else if (data.submitter.name.toLowerCase().includes(query) || data.kind.toLowerCase().includes(query)) {
                                return data;
                            }
                        }
                        }).map((leaveform) => (
                            leaveformList = <Leaveforms
                            key={leaveform.id}
                            id={leaveform.id}
                            leaveform_id={leaveform.leaveform_id}
                            submission_date={leaveform.submission_date}
                            reasonForRequest={leaveform.reasonForRequest}
                            remark={leaveform.remark}
                            submitter={leaveform.submitter.name}
                            kind={leaveform.kind}
                            leaveform_dates={leaveform.leaveform_dates}
                            leaveTotalDays={leaveform.leaveTotalDays}
        
                    
                    /> 
                    ))}
                    </Tbody>
                } else {
                    return <Tbody>
                        {data?.filter((data) => { 
                        if(data.submitter.minimax_number===minimax_number) {
                            if(query === '') { 
                                return data;
                            } else if (data.submitter.name.toLowerCase().includes(query) || data.kind.toLowerCase().includes(query)) {
                                return data;
                            }
                        }
                        }).map((leaveform) => (
                            leaveformList = <Leaveforms
                            key={leaveform.id}
                            id={leaveform.id}
                            leaveform_id={leaveform.leaveform_id}
                            submission_date={leaveform.submission_date}
                            reasonForRequest={leaveform.reasonForRequest}
                            remark={leaveform.remark}
                            submitter={leaveform.submitter.name}
                            kind={leaveform.kind}
                            leaveform_dates={leaveform.leaveform_dates}
                            leaveTotalDays={leaveform.leaveTotalDays}
        
                    
                    /> 
                    ))}
                    </Tbody>
                }
                }
        return leaveformList = <Tbody><Tr><Td></Td></Tr></Tbody>
    }
    return (
        <Grid mt={10} columnGap={7} rowGap={5} templateColumns={{ base: "repeat(1fr)", lg: "repeat(1fr)"}} px={{base: 0, lg: 10}}>
            {isLoading ? (
            <>
                <LeaveformSkeleton />
                <LeaveformSkeleton />
                <LeaveformSkeleton />
                <LeaveformSkeleton />
                <LeaveformSkeleton />
                <LeaveformSkeleton />
                <LeaveformSkeleton />
                <LeaveformSkeleton />
                <LeaveformSkeleton />
                <LeaveformSkeleton />
                <LeaveformSkeleton />
                <LeaveformSkeleton />
                <LeaveformSkeleton />
                
                
            </>
            ) : null }
            <Flex>
            <FormControl>
            <InputGroup w={{ base: '100%', md: '50%' }} {...rest}>
                <InputLeftElement
                    children={
                        <IconButton
                            aria-label='search'
                            bg='none'
                            borderRadius='inherit'
                            _hover={{
                                bg: 'none',
                            }}
                            _active={{
                                bg: 'none',
                                transform: 'none',
                                borderRadius: 'inherit',
                                borderColor: 'transparent'
                            }}
                            _focus={{
                                boxShadow: 'none',
                                borderRadius:'inherit'
                            }}
                            icon={<SearchIcon color={searchIconColor} w='15px' h='15px' borderRadius='inherit'
                            />}
                        />
                    }
                />
                <Input
                    variant='search'
                    border="1px solid #f0f0f0"

                    fontSize='sm'
                    color={inputText}
                    fontWeight='500'
                    _placeholder={{ color: 'gray.400', fontSize: '14px' }}
                    borderRadius={borderRadius ? borderRadius : '30px'}
                    placeholder={placeholder ? placeholder : 'Search...'}
                    type="text"
                    onChange={(e) => setQuery(e.target.value)}
                    />
            </InputGroup>
            </FormControl>
            
            <Spacer />
            <Button onClick={exportToExcel} colorScheme="teal" mr={3}  size="sm">
                엑셀
            </Button>
            <Link href="/leaveforms/upload">
                <Button size="sm">+ Leave Form 생성하기</Button>
            </Link>
            </Flex>
            <Box>
                <HStack>
                    <TableContainer w="100%">
                        <Table size='sm'>
                            <Thead>
                                <Tr>
                                    <Th >Leave Form ID</Th>
                                    {userData?.role == "Boss" && (
                                    <Th>추가설명(과장님)</Th>
                                    )}
                                    {userData?.role == "Manager" && (
                                    <Th>추가설명(과장님)</Th>
                                    )}
                                    <Th fontSize="xs" >Submit Date</Th>
                                    <Th fontSize="xs" >Period</Th>
                                    <Th fontSize="xs" >Total Days</Th>
                                    <Th fontSize="xs" >Name</Th>
                                    <Th fontSize="xs" >Kind</Th>
                                </Tr> 
                            </Thead>
                            <RoleCheck />
                        </Table>
                    </TableContainer>
                </HStack>
            </Box>
        </Grid>
    )
}  